:root {
  --primary-color: #F4B083;
  --secondary-color: #1EB8B2;
  --bg-color: rgb(239, 239, 239);
  --accent-color: magenta;
  --text-color: black;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: var(--bg-color);
  position: fixed;
  top: 0;
  color: var(--text-color);
  overflow: scroll;
  /* padding-top: 1vh; */


}

a {
  text-decoration: none;
  font-weight: 500;
  color: var(--secondary-color)
}

#footer {
  position: absolute;
  bottom: 0;
  font-family: 'Courier New', Courier, monospace;
  width: 100vw;
  background-color: var(--bg-color);
  border-top: solid rgba(134, 134, 134, 0.257) 1px;
  font-size: .8em;
  font-weight: 100;
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

#App-logo {
  /* height: 40vmin; */
  max-width: 600px;
  min-width: 350px;
  position: relative;
  /* pointer-events: none; */
}

.shifted-up {
  animation: shift-up 2s;
  bottom: 12vh;
}

.shifted-down {
  animation: shift-down 2s;
  bottom: 0
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

#header {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

#bio {
  margin: 5vh auto;
  max-width: 600px;
  /* max-height: 30vh; */
  /* min-width: 400px; */
  font-size: 1.1em;
  text-align: justify;
  word-break: normal;
  font-weight: 200;
}

.disappear {
  opacity: 0;
  transition: opacity 1s ease-out;
}

#menu {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.menu-option {
  padding: 1em;
  font-size: 1.4em;
  color: var(--secondary-color);
}

.menu-option:hover  {
  background-color: var(--secondary-color);
  color: var(--bg-color);
  /* padding: 1em;
  font-size: 1.4em; */
}

.App-link {
  color: var(--accent-color);
}

#view-box {
  position: fixed;
  height: calc(80vh - 70px);
  width: 100vw;
  border-radius: 10px 10px 0 0;
  border-top: solid rgba(134, 134, 134, 0.257) 1px;
  border-bottom: none;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  padding: 0 20px 20px 20px;
  margin-bottom: 30px;
  /* -webkit-box-shadow: -1px 1px 11px 4px rgba(0,0,0,0.26); 
  box-shadow: inset -1px 1px 11px 4px rgba(0,0,0,0.26); */


  /* animation: view-box-open 2s; */
  /* bottom: 0; */
  
}

.view-box-open {
  animation: view-box-open 2s;
  bottom: 0;
}

.view-box-close {
  animation: view-box-close 2s;
  bottom: -80vh;
}

.view-box-header {
  /* width: 100%; */
  text-align: center;
  color: var(--primary-color);
  font-size: 2em;
  font-weight: 700;
  margin-top: 20px;
  background-color: var(--bg-color);
  padding: 10px;
}

.view-box-description {
  max-width: 600px;
  margin: 1.5em auto;
  font-weight: 200;
  font-size: 1em;
  text-align: justify;
  background-color: var(--bg-color);
  padding: 10px;

}

.view-box-control-bar {
  width: 100%;
  text-align: center;
  padding-top: 10px;
  background-color: transparent;
  position: fixed;
  /* margin-top: 20px; */
}

.view-box-content {
  display: flex;
  flex-grow: 1;

  flex-direction: column;
  width: 90vw;
  padding: 100px 5vw 160px 5vw;
  overflow: auto;
  align-items: center;
  gap: 40px;
  margin-top: 0px;
}

.view-box-content-card {
  width: 260px;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--bg-color);
  padding: 20px;
  gap: 15px;
  border-radius: 10px;
  -webkit-box-shadow: -1px 1px 5px 2px rgba(0,0,0,0.15); 
  box-shadow: -1px 1px 5px 2px rgba(0,0,0,0.15);

}

.view-box-team-card {
  width: 600px;
  min-height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* gap: 10px; */
  background-color: var(--bg-color);
  padding: 20px;
  border-radius: 10px;
  -webkit-box-shadow: -1px 1px 5px 2px rgba(0,0,0,0.15); 
  box-shadow: -1px 1px 5px 2px rgba(0,0,0,0.15);
}

.view-box-content-card-header,
.view-box-work-card-header {
  /* width: 100%; */
  text-align: left;
  color: var(--primary-color);
  font-size: 1.3em;
  font-weight: 400;
  max-width: 300px;
  /* height: 80px; */
  /* margin-top: 20px; */
}
.view-box-work-card-header {
  font-size: 1.5em;
  margin-bottom: 1em
}

.view-box-content-card-email,
.view-box-content-card-text {
  font-weight: 200;
}

.view-box-content-card-email {
  text-align: left;
}

.view-box-content-card-email a{
  font-size: 1.2em;
  color: var(--secondary-color);
  font-weight: 300;
}

.view-box-work-card {
  width: 80vw;
  max-width: 800px;
  height: fit-content;
  /* height: 260px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 10px; */
  background-color: var(--bg-color);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  -webkit-box-shadow: -1px 1px 5px 2px rgba(0,0,0,0.15); 
  box-shadow: -1px 1px 5px 2px rgba(0,0,0,0.15);
}

.view-box-work-card-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.view-box-team-card-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  
}

.view-box-team-card-name {
  font-size: 1.2em;
}

.view-box-team-card-role {
  font-weight: 300;
  font-style: italic;
}

.view-box-work-card-description {
  /* width: calc(80vw - 300px); */
  font-weight: 200;
  /* height: 200px; */
  overflow: scroll;
  padding-left: 40px;
  padding-right: 20px;
  padding-bottom: 20px;
  font-size: .9em;
  text-align: justify;
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  justify-content: flex-start;

}


.view-box-team-card-description {
  font-size: .9em;
  font-weight: 200;
  margin-top: 20px;
}

.view-box-work-card-image img {
  width: 300px;
  -webkit-bo-shadow: -1px 1px 11px 4px rgba(0,0,0,0.26); 
  box-shadow: -1px 1px 11px 4px rgba(0,0,0,0.26);

}

.view-box-team-card-image img {
  width: 250px;
  border-radius: 50%;
}

.view-box-work-card-image img:hover {
  -webkit-bo-shadow: -1px 1px 11px 4px var(--primary-color); 
  box-shadow: -1px 1px 11px 4px var(--primary-color);
}



@media only screen and (max-width: 800px ) {
  .App {
    padding-top: 10px;
    justify-content: flex-start;
  }
  .view-box-team-card,
  .view-box-work-card {
    width: 80vw;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    gap: 20px;
  }
  

  .view-box-work-card-body {
    flex-direction: column;
    gap: 20px;
  }

  .view-box-description {
    width: 80vw;
    font-size: .9em;
  }

  
  #App-logo,
  #bio p {
    width: 80vw;
    margin: auto
  }
  
  #bio p {
    font-size: .8em;
    margin-top: 20px;
  }

  .shifted-up {
    animation: shift-up-mobile-short 2s;
    bottom: 1vh;
   }
   
   .shifted-down {
    animation: shift-down-mobile-short 2s;
    bottom: 0
   }

  .menu-option {
    padding: .3em;
    font-size: 1.4em;
    color: var(--secondary-color);
  }

  .view-box-content {
    padding: 100px 5vw 160px 5vw;
    margin-top: 0px;
  }

  #footer {
    height: 140px;
    padding-top: 0;
    align-items: flex-start;
  }

  #footer > p {
    font-size: .8em;
    padding-top: 15px;
    margin: 0px;
  }
  

  /* #bio {
    width: 300px;
  } */
}

@media only screen and (max-height: 670px ) {
  .shifted-up {
   animation: shift-up-mobile-short 2s; 
    bottom: 1vh;
  }
  
  .shifted-down {
     animation: shift-down-mobile-short 2s; 
    bottom: 0
  }

  #view-box {
    height: 70vh;
  }



  .view-box-open {
    animation: view-box-open-short 2s;
    bottom: 0;
  }
  
  .view-box-close {
    animation: view-box-close-short 2s;
    bottom: -70vh;
  }

}

@keyframes view-box-open {
  from {
    bottom: -80vh;
  }
  to {
    bottom: 0
  }
}

@keyframes view-box-close {
  from {
    bottom: 0;
  }
  to {
    bottom: -80vh;
  }
}

@keyframes shift-up {
  from {
    bottom: 0;
  }
  to {
    bottom: 12vh;
  }
}

@keyframes shift-down {
  from {
    bottom: 12vh;
  }
  to {
    bottom: 0;
  }
}

@keyframes shift-up-mobile {
  from {
    bottom: 0;
  }
  to {
    bottom: 15vh;
  }
}

@keyframes shift-down-mobile {
  from {
    bottom: 15vh;
  }
  to {
    bottom: 0;
  }
}

@keyframes shift-up-mobile-short {
  from {
    bottom: 0;
  }
  to {
    bottom: 1vh;
  }
}

@keyframes shift-down-mobile-short {
  from {
    bottom: 1vh;
  }
  to {
    bottom: 0;
  }
}

@keyframes view-box-open-short {
  from {
    bottom: -70vh;
  }
  to {
    bottom: 0
  }
}

@keyframes view-box-close-short {
  from {
    bottom: 0;
  }
  to {
    bottom: -70vh;
  }
}